import androidx.compose.runtime.CompositionLocalProvider
import com.varabyte.kobweb.core.AppGlobals
import com.varabyte.kobweb.navigation.RoutePrefix
import com.varabyte.kobweb.navigation.Router
import com.varabyte.kobweb.navigation.UpdateHistoryMode
import com.varabyte.kobweb.silk.defer.DeferringHost
import kotlinx.browser.document
import kotlinx.browser.window
import org.jetbrains.compose.web.renderComposable

public fun main() {
    RoutePrefix.set("")
    val router = Router()
    com.varabyte.kobweb.core.init.initKobweb(router) { ctx ->
        ctx.router.register("/") { com.sanskar.rij.pages.HomePage() }

    }
    router.addRouteInterceptor {
        path = path.removeSuffix(".html").removeSuffix(".htm")
    }

    com.varabyte.kobweb.silk.init.additionalSilkInitialization = { ctx ->
        com.varabyte.kobweb.silk.init.initSilkWidgets(ctx)
        com.varabyte.kobweb.silk.init.initSilkWidgetsKobweb(ctx)
        ctx.theme.registerStyle("about-image", com.sanskar.rij.style.AboutImageStyle)
        ctx.theme.registerStyle("about-text", com.sanskar.rij.style.AboutTextStyle)
        ctx.theme.registerStyle("back-to-top-button", com.sanskar.rij.style.BackToTopButtonStyle)
        ctx.theme.registerStyle("input", com.sanskar.rij.style.InputStyle)
        ctx.theme.registerStyle("navigation-item", com.sanskar.rij.style.NavigationItemStyle)
        ctx.theme.registerStyle("logo", com.sanskar.rij.style.LogoStyle)
        ctx.theme.registerStyle("social-link", com.sanskar.rij.style.SocialLinkStyle)
        ctx.theme.registerStyle("main-button", com.sanskar.rij.style.MainButtonStyle)
        ctx.theme.registerStyle("portfolio-section", com.sanskar.rij.style.PortfolioSectionStyle)
        ctx.theme.registerStyle("portfolio-arrow", com.sanskar.rij.style.PortfolioArrowStyle)
        ctx.theme.registerStyle("service-card", com.sanskar.rij.style.ServiceCardStyle)
    }

    // For SEO, we may bake the contents of a page in at build time. However, we will
    // overwrite them the first time we render this page with their composable, dynamic
    // versions. Think of this as poor man's hydration :)
    // See also: https://en.wikipedia.org/wiki/Hydration_(web_development)
    router.navigateTo(window.location.href.removePrefix(window.location.origin),
            UpdateHistoryMode.REPLACE)

    val root = document.getElementById("root")!!
    while (root.firstChild != null) { root.removeChild(root.firstChild!!) }

    AppGlobals.initialize(mapOf("title" to "Sanskar"))
    renderComposable(rootElementId = "root") {
        com.sanskar.rij.AppEntry {
            router.renderActivePage { DeferringHost { it() } }
        }
    }
}
